@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  @font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./assets/fonts/AvenirNextLTPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./assets/fonts/AvenirNextLTPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next LT Pro';
    src: url('./assets/fonts/AvenirNextLTPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
}

/* services slide styles */
.custom-pagination .swiper-pagination-bullet{
  width: 15px;
  height: 15px;
  background-color: #000;
  border: #e9d217 2px solid;
}

.custom-pagination .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background-color: #e9d217;
}

/* project slide styles */
.project .swiper-slide-next,
.project .swiper-slide-prev {
 opacity: 0.4;
}

form input {
  caret-color: #fff;
}